import * as $ from 'jquery'
export default {
    name: 'home',
    components: {
        
    },
    data () {
		return {
			active:'Printing',
		}
    },
    methods: {
		
    },
    created () {
		
    }
}
  