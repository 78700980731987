export default {
    name: 'sla',
    components: {
        
    },
    data () {
		return {
            
		}
    },
    methods: {
		
    },
    created () {
		
    }
}
  